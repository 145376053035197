require('./bootstrap');

// Datatables
require('datatables.net');
require('datatables.net-bs4');
require('datatables.net-responsive/js/dataTables.responsive.min.js');
require('datatables.net-responsive-bs4/js/responsive.bootstrap4.min.js');
require('datatables.net-scroller/js/dataTables.scroller.min.js');

// MomentJS
window.moment = require('moment');
require('moment/locale/id.js');

// Flatpickr
require('flatpickr');
import {Indonesian} from 'flatpickr/dist/l10n/id.js';
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect/index';

// Stikcyfilljs
require('stickyfilljs');
require('sticky-kit/dist/sticky-kit.js');
require('is_js');

// Fontawesome
require('@fortawesome/fontawesome-free/js/all.min.js');

// JQuery timeago
require('timeago/jquery.timeago.js');
require('timeago/locales/jquery.timeago.id.js');

// Select2
require('select2/dist/js/select2.js');

// Inputmask
require('inputmask/dist/inputmask.min.js');
require('inputmask/dist/jquery.inputmask.min.js');

// Sweetalert2
window.Swal = require('sweetalert2');

// PrintThis
require('print-this/printThis.js');

// Jquery Form
require('jquery-form/src/jquery.form.js');

// waitMe JS
require('waitme/waitMe.js');

// tinyMce
require('tinymce');
require('tinymce/themes/silver');
require('tinymce/icons/default');
require('tinymce/plugins/advlist');
require('tinymce/plugins/autolink');
require('tinymce/plugins/link');
require('tinymce/plugins/image');
require('tinymce/plugins/lists');
require('tinymce/plugins/charmap');
require('tinymce/plugins/print');
require('tinymce/plugins/preview');
require('tinymce/plugins/hr');
require('tinymce/plugins/anchor');
require('tinymce/plugins/pagebreak');
require('tinymce/plugins/searchreplace');
require('tinymce/plugins/wordcount');
require('tinymce/plugins/visualblocks');
require('tinymce/plugins/visualchars');
require('tinymce/plugins/code');
require('tinymce/plugins/fullscreen');
require('tinymce/plugins/insertdatetime');
require('tinymce/plugins/media');
require('tinymce/plugins/nonbreaking');
require('tinymce/plugins/table');
require('tinymce/plugins/emoticons');
require('tinymce/plugins/emoticons/js/emojiimages.js');
require('tinymce/plugins/emoticons/js/emojis.js');
require('tinymce/plugins/template');
require('tinymce/plugins/paste');
require('tinymce/plugins/help');

// signature pad
// require('signature_pad');

// NProgress
window.NProgress = require('nprogress/nprogress.js');

// accounting.js
window.accounting = require('accounting');

/**
 * Custom JS
 */

// Get URL parameter
window.getUrlParameter = function(name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

// Remove the formatting to get integer data for summation
window.intVal = function(i) {
    return typeof i === 'string' ?
        i.replace(/[\$,]/g, '')*1 :
        typeof i === 'number' ?
            i : 0;
};

// Crop whitespaces of canvas js
window.getCroppedCanvasImage = function(canvas) {

    let originalCtx = canvas.getContext('2d');

    let originalWidth = canvas.width;
    let originalHeight = canvas.height;
    let imageData = originalCtx.getImageData(0,0, originalWidth, originalHeight);

    let minX = originalWidth + 1, maxX = -1, minY = originalHeight + 1, maxY = -1, x = 0, y = 0, currentPixelColorValueIndex;

    for (y = 0; y < originalHeight; y++) {
        for (x = 0; x < originalWidth; x++) {
            currentPixelColorValueIndex = (y * originalWidth + x) * 4;
            let currentPixelAlphaValue = imageData.data[currentPixelColorValueIndex + 3];
            if (currentPixelAlphaValue > 0) {
                if (minX > x) minX = x;
                if (maxX < x) maxX = x;
                if (minY > y) minY = y;
                if (maxY < y) maxY = y;
            }
        }
    }

    let croppedWidth = maxX - minX;
    let croppedHeight = maxY - minY;
    if (croppedWidth < 0 || croppedHeight < 0) return null;
    let cuttedImageData = originalCtx.getImageData(minX, minY, croppedWidth, croppedHeight);

    let croppedCanvas = document.createElement('canvas'),
        croppedCtx    = croppedCanvas.getContext('2d');

    croppedCanvas.width = croppedWidth;
    croppedCanvas.height = croppedHeight;
    croppedCtx.putImageData(cuttedImageData, 0, 0);

    return croppedCanvas.toDataURL();
};

$(document).ready(function() {
    // enabled font awesome 5 pseudo element
    window.FontAwesomeConfig = {
        searchPseudoElements: true
    }

    // ---------------------------------------------------------------------------------------- //
    let uri = window.location.href;

    $('[href="' + uri + '"]').closest('li.nav-item').addClass('active');
    $('[href="' + uri + '"]').closest('li.nav-item').closest('ul.nav.collapse').addClass('show');

    // ---------------------------------------------------------------------------------------- //
    // TinyMCE
    // ---------------------------------------------------------------------------------------- //
    tinymce.init({
        selector: '.complete-wysiwyg',
        menubar: true,
        statusbar: false,
        inline_boundaries: false,
        relative_urls: false,
        file_picker_callback (callback, value, meta) {
            let x = window.innerWidth || document.documentElement.clientWidth || document.getElementsByTagName('body')[0].clientWidth
            let y = window.innerHeight|| document.documentElement.clientHeight|| document.getElementsByTagName('body')[0].clientHeight

            tinymce.activeEditor.windowManager.openUrl({
                url : '/file-manager/tinymce5',
                title : 'Laravel File manager',
                width : x * 0.8,
                height : y * 0.8,
                onMessage: (api, message) => {
                    callback(message.content, { text: message.text })
                }
            })
        },
        plugins: [
            'advlist autolink link image lists charmap print preview hr anchor pagebreak',
            'searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking',
            'table emoticons template paste help'
        ],
        toolbar: 'undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | forecolor backcolor emoticons'
    });

    tinymce.init({
        selector: '.message-wysiwyg',
        menubar: false,
        statusbar: false,
        inline_boundaries: false,
        relative_urls: false,
        file_picker_callback (callback, value, meta) {
            let x = window.innerWidth || document.documentElement.clientWidth || document.getElementsByTagName('body')[0].clientWidth
            let y = window.innerHeight|| document.documentElement.clientHeight|| document.getElementsByTagName('body')[0].clientHeight

            tinymce.activeEditor.windowManager.openUrl({
                url : '/file-manager/tinymce5',
                title : 'Laravel File manager',
                width : x * 0.8,
                height : y * 0.8,
                onMessage: (api, message) => {
                    callback(message.content, { text: message.text })
                }
            })
        },
        plugins: [
            'advlist autolink link image lists charmap print preview hr anchor pagebreak',
            'searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking',
            'table emoticons template paste help'
        ],
        toolbar: 'undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link | forecolor backcolor emoticons'
    });

    // ---------------------------------------------------------------------------------------- //
    // jQuery timeago
    // ---------------------------------------------------------------------------------------- //
    $("time.timeago").timeago();

    // ---------------------------------------------------------------------------------------- //
    // Flatpicker
    // ---------------------------------------------------------------------------------------- //
    flatpickr.localize(Indonesian);

    $(".flatpickr").flatpickr({
        dateFormat: 'Y-m-d',
        altInput: true,
        altFormat: 'd/m/Y',
        disableMobile: true
    });

    $(".flatpickr-full").flatpickr({
        dateFormat: 'Y-m-d',
        altInput: true,
        altFormat: 'l, d F Y',
        disableMobile: true
    });

    $(".flatpickr-range").flatpickr({
        mode: 'range',
        dateFormat: 'Y-m-d',
        altInput: true,
        altFormat: 'd/m/Y',
        disableMobile: true
    });

    $(".flatpickr-time").flatpickr({
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        time_24hr: true,
        disableMobile: true
    });

    $(".flatpickr-month-select").flatpickr({
        altInput: true,
        disableMobile: false,
        plugins: [
            new monthSelectPlugin({
                shorthand: true,
                dateFormat: "Y-m",
                altFormat: "F Y"
            })
        ]
    });

    // ---------------------------------------------------------------------------------------- //
    // Inputmask
    // ---------------------------------------------------------------------------------------- //
    $('.inputmask-clock').inputmask('99:99');
    $('.inputmask-year').inputmask('9999');

    // ---------------------------------------------------------------------------------------- //
    // select 2
    // ---------------------------------------------------------------------------------------- //
    $('.select2-single').select2();

    // ---------------------------------------------------------------------------------------- //
    // Moment JS
    // ---------------------------------------------------------------------------------------- //
    moment.locale('id');        

    $('.moment').html(function() {
        return moment($(this).data('datetime')).format($(this).data('format'));
    });

    $('.moment-time').html(function() {
        return moment($(this).data('datetime'), "HH:mm:ss").format($(this).data('format'));
    });

    // ---------------------------------------------------------------------------------------- //
    // Form behaviour
    // ---------------------------------------------------------------------------------------- //
    $('form').on('reset', function() {
        $(this).find('.select2-single').not('[multiple]').val(function() {
            return $(this).find('option:first').val();
        }).trigger('change');

        $(this).find('select[multiple]').val('').trigger('change');
    });

    // ---------------------------------------------------------------------------------------- //
    // Bootstrap Popover
    // ---------------------------------------------------------------------------------------- //
    $('[data-toggle="popover"]').popover({
        html: true
    });
});